import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";

import QuizBody from "./Quiz/Components/QuizBody";

// Add icons to the library
// Filter out only the valid icons
const validIcons = Object.keys(solidIcons)
  .filter((key) => key.startsWith("fa"))
  .map((key) => solidIcons[key]);

// Add valid icons to the library
library.add(...validIcons);

function App() {
  return (
    <div className="App">
      <QuizBody />
    </div>
  );
}

export default App;
