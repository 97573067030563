import React from "react";
import styled from "styled-components";

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

const PillOption = styled.button`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 1.5rem;
  background-color: ${({ selected }) =>
    selected ? "var(--brand-color)" : "#fff"};
  color: ${({ selected }) => (selected ? "#fff" : "var(--brand-color)")};
  border: 3px solid var(--brand-color);
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
`;

const CitySelector = ({ selections, setSelections }) => {
  function clickHandler(event) {
    console.log("radioClickHandler -> event", event);
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          [event.target.name]: event.target.value,
        };
        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          Which city would you like to live in?
        </div>
      </div>
      <div className="non-grid-options-section">
        <PillContainer>
          <PillOption
            name="city"
            value="Brisbane"
            selected={selections.city === "Brisbane"}
            onClick={(event) => clickHandler(event)()}
          >
            Brisbane
          </PillOption>

          <PillOption
            name="city"
            value="Cairns"
            selected={selections.city === "Cairns"}
            onClick={(event) => clickHandler(event)()}
          >
            Cairns
          </PillOption>

          <PillOption
            name="city"
            value="GoldCoast"
            selected={selections.city === "GoldCoast"}
            onClick={(event) => clickHandler(event)()}
          >
            Gold Coast
          </PillOption>

          <PillOption
            name="city"
            value="Rockhampton"
            selected={selections.city === "Rockhampton"}
            onClick={(event) => clickHandler(event)()}
          >
            Rockhampton
          </PillOption>

          <PillOption
            name="city"
            value="SunshineCoast"
            selected={selections.city === "SunshineCoast"}
            onClick={(event) => clickHandler(event)()}
          >
            Sunshine Coast
          </PillOption>

          <PillOption
            name="city"
            value="Toowoomba"
            selected={selections.city === "Toowoomba"}
            onClick={(event) => clickHandler(event)()}
          >
            Toowoomba
          </PillOption>

          <PillOption
            name="city"
            value="Townsville"
            selected={selections.city === "Townsville"}
            onClick={(event) => clickHandler(event)()}
          >
            Townsville
          </PillOption>
        </PillContainer>
      </div>
    </div>
  );
};

export default CitySelector;
