import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookF,
  faInstagram,
  faYoutube,
  faWeibo,
} from "@fortawesome/free-brands-svg-icons";

import styled from "styled-components";

//import Whitelogo from "../assets/Logos/Study Queensland - white.png";
import Whitelogo from "../assets/Logos/Study QLD_TIQ_REVERSE.png";

const FooterWrapper = styled.footer`
  background-color: var(--brand-color);

  .footer-content {
    max-width: var(--max-width);
    margin-top: 4rem;
    margin-inline: auto;
    padding: 3rem 2rem;

    hr {
      color: white;
      height: 2px; /* Adjust the thickness as needed */
      background-color: white; /* Ensure the background color is also white */
      border: none;
    }

    .footer-links {
      display: flex;
      justify-content: space-between;
      margin-block: 2rem;

      a {
        color: white;
        text-decoration: none;
        font-size: 1.2rem;
      }
    }

    @media (max-width: 600px) {
      .footer-links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: start;
        gap: 2rem;
      }
    }

    .site-info-section {
      display: flex;

      .logo {
        flex: 1;
        align-self: center;

        img {
          height: 3rem;
        }
      }

      .socials {
        margin-left: auto;
        h5 {
          color: white;
          font-size: 1.5rem;
          margin-block: 0.5rem;
        }
        .socials-buttons {
          display: flex;
          gap: 1rem; /* Adjust the gap between icons as needed */

          .social-btn {
            background-color: white;
            border-radius: 50%;
            width: 40px; /* Adjust the size as needed */
            height: 40px; /* Ensure this is equal to width */
            display: flex;
            align-items: center;
            justify-content: center;

            .fa-icon {
              color: var(--brand-color);
              font-size: 1.5em;
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      .site-info-section {
        flex-direction: column-reverse;
        gap: 4rem;

        .logo{
            align-self: start;
            
            img {
              height: auto;
              max-width: 100%;
            }
        }

        .socials {
          margin: 0;
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <footer className="footer-content">
        {/* <hr />
        <div className="footer-links">
          <a href="#" aria-label="Study Queensland">
            Study Queensland
          </a>
          <a href="#" aria-label="Industry">
            Industry
          </a>
          <a href="#" aria-label="Live">
            Live
          </a>
          <a href="#" aria-label="Work">
            Work
          </a>
          <a href="#" aria-label="Services and Support">
            Services and Support
          </a>
          <a href="#" aria-label="About">
            About
          </a>
        </div>
        <hr /> */}
        <div className="site-info-section">
          <div className="logo">
            <img
              src={Whitelogo}
              alt="Study Queensland Logo"
              onClick={() =>
                window.open("http://studyqueensland.qld.gov.au/", "_blank")
              }
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="socials">
            <h5 tabIndex={0}>Connect with us</h5>
            <div className="socials-buttons" aria-label="Social Media Links">
              <a
                className="social-btn"
                href="https://www.facebook.com/studyqueensland"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} className="fa-icon" />
              </a>
              <a
                className="social-btn"
                href="https://www.instagram.com/studyqueensland/"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} className="fa-icon" />
              </a>
              <a
                className="social-btn"
                href="https://www.youtube.com/channel/UCJs1kk9Nb5fPJHEmSqVUnyQ"
                aria-label="YouTube"
              >
                <FontAwesomeIcon icon={faYoutube} className="fa-icon" />
              </a>
              <a
                className="social-btn"
                href="https://weibo.com/StudyQueensland"
                aria-label="Weibo"
              >
                <FontAwesomeIcon icon={faWeibo} className="fa-icon" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </FooterWrapper>
  );
};

export default Footer;
