import React from "react";
import styled from "styled-components";
import cityCostData from "../../Data-sets/StudyQld-cost-data";

import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as EatingOutIcon } from "../../assets/icons/eatingout.svg";

import PopupModal from "../Components/PopupModal";

const Question5 = ({ selections, setSelections, setFoodCost }) => {
  React.useEffect(() => {
    setFoodCost(
      cityCostData[selections.city].foodPriceRange.eatingOut.breakfast[
        selections.food.eatingOut
      ] *
        selections.food.breakfastOut +
        cityCostData[selections.city].foodPriceRange.eatingOut.lunch[
          selections.food.eatingOut
        ] *
          selections.food.lunchOut +
        cityCostData[selections.city].foodPriceRange.eatingOut.dinner[
          selections.food.eatingOut
        ] *
          selections.food.dinnerOut +
        (cityCostData[selections.city].foodPriceRange.atHome.breakfast[
          selections.food.atHome
        ] *
          (7 - selections.food.breakfastOut) +
          cityCostData[selections.city].foodPriceRange.atHome.lunch[
            selections.food.atHome
          ] *
            (7 - selections.food.lunchOut) +
          cityCostData[selections.city].foodPriceRange.atHome.dinner[
            selections.food.atHome
          ] *
            (7 - selections.food.dinnerOut))
    );
  }, []);

  function onChangeHandler(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          food: {
            ...prevSelection.food,
            [event.target.name]: event.target.value,
          },
        };
        setFoodCost(
          cityCostData[selections.city].foodPriceRange.eatingOut.breakfast[
            updatedSelections.food.eatingOut
          ] *
            updatedSelections.food.breakfastOut +
            cityCostData[selections.city].foodPriceRange.eatingOut.lunch[
              updatedSelections.food.eatingOut
            ] *
              updatedSelections.food.lunchOut +
            cityCostData[selections.city].foodPriceRange.eatingOut.dinner[
              updatedSelections.food.eatingOut
            ] *
              updatedSelections.food.dinnerOut +
            (cityCostData[selections.city].foodPriceRange.atHome.breakfast[
              updatedSelections.food.atHome
            ] *
              (7 - updatedSelections.food.breakfastOut) +
              cityCostData[selections.city].foodPriceRange.atHome.lunch[
                updatedSelections.food.atHome
              ] *
                (7 - updatedSelections.food.lunchOut) +
              cityCostData[selections.city].foodPriceRange.atHome.dinner[
                updatedSelections.food.atHome
              ] *
                (7 - updatedSelections.food.dinnerOut))
        );
        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          What price range does your food fit into?
        </div>
        <PopupModal
          modalHeading="What price range does your food fit into?"
          modalContent={
            <div class="modal-info">
              <p tabIndex={0}>
                A key part of the international study experience is immersing
                yourself in your new city, including its restaurants, bars and
                cafés. Do you plan to enjoy nice meals with friends or will you
                be selecting cheaper options when dining out?
              </p>
              <p tabIndex={0}>
                If you do decide to have a meal out, look for happy hours,
                midweek discounts and daily specials. Many pubs and restaurants
                also offer student discounts, so be sure to do your research or
                ask the staff about these deals.
              </p>
            </div>
          }
        />
      </div>
      <div className="non-grid-options-section">
        <div className="radio-wrapper">
          <HomeIcon />
          <div className="options-wrapper">
            <p>When at home</p>
            <div className="option">
              <input
                type="radio"
                name="atHome"
                value="cheap"
                checked={selections.food.atHome === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "atHome", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="atHome"
                value="moderate"
                checked={selections.food.atHome === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "atHome", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="atHome"
                value="expensive"
                checked={selections.food.atHome === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "atHome", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <EatingOutIcon />
          <div className="options-wrapper">
            <p>When eating out (if applicable)</p>
            <div className="option">
              <input
                type="radio"
                name="eatingOut"
                value="cheap"
                checked={selections.food.eatingOut === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "eatingOut", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="eatingOut"
                value="moderate"
                checked={selections.food.eatingOut === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "eatingOut", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="eatingOut"
                value="expensive"
                checked={selections.food.eatingOut === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "eatingOut", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question5;
