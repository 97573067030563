import React from "react";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import StatusBar from "./StatusBar";
import CitySelector from "../Questions/CitySelector";
import Question1 from "../Questions/Question1";
import Question2 from "../Questions/Question2";
import Question3 from "../Questions/Question3";
import Question4 from "../Questions/Question4";
import Question5 from "../Questions/Question5";
import Question6 from "../Questions/Question6";
import Question7 from "../Questions/Question7";
import Question8 from "../Questions/Question8";
import Result from "../Questions/Result";

const QuizBody = () => {
  const [questionNumber, setQuestionNumber] = React.useState(0);
  const [selections, setSelections] = React.useState({
    city: "Brisbane",
    accommodation: {
      location: "kindCity",
      accommodationType: "homestay",
    },
    transport: {
      bike: "never",
      publicTransport: "never",
      car: "never",
      taxi: "never",
    },
    food: {
      breakfastOut: "3",
      lunchOut: "3",
      dinnerOut: "3",
      atHome: "cheap",
      eatingOut: "cheap",
    },
    products: {
      gyms: "none",
      haircuts: "none",
      cosmetics: "none",
      mobilePlan: "none",
    },
    clothing: {
      cost: "cheap",
      quantity: "0",
    },
    activities: {
      cinemas: "never",
      liveMusic: "never",
      socialSport: "never",
      nightOut: "never",
    },
  });
  const [accommodationCost, setAccommodationCost] = React.useState(0);
  const [transportCost, setTransportCost] = React.useState(0);
  const [foodCost, setFoodCost] = React.useState(0);
  const [productCost, setProductCost] = React.useState(0);
  const [clothingCost, setClothingCost] = React.useState(0);
  const [activitiesCost, setActivitiesCost] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState(0);

  //these are used for displaying the cost frequency and currency
  const [costPer, setCostPer] = React.useState(1);
  const [currency, setCurrency] = React.useState({
    symbol: "$",
    name: "AUD",
    exchangeRate: 1,
  });

  const [originalCosts, setOriginalCosts] = React.useState({
    accommodation: 0,
    transport: 0,
    food: 0,
    product: 0,
    clothing: 0,
    activities: 0,
    total: 0,
  });

  React.useEffect(() => {
    function calculateTotalCost() {
      setTotalCost(
        accommodationCost +
          transportCost +
          foodCost +
          productCost +
          clothingCost +
          activitiesCost
      );
    }
    calculateTotalCost();
  }, [
    accommodationCost,
    activitiesCost,
    clothingCost,
    foodCost,
    productCost,
    transportCost,
  ]);

  function renderNextQuestion() {
    setQuestionNumber((questionNo) => (questionNo < 9 ? questionNo + 1 : 9));
  }

  function renderPrevQuestion() {
    setQuestionNumber((questionNo) => (questionNo > 1 ? questionNo - 1 : 0));
  }

  return (
    <div>
      <Header />
      <StatusBar
        accommodationCost={accommodationCost}
        transportCost={transportCost}
        foodCost={foodCost}
        productCost={productCost}
        clothingCost={clothingCost}
        activitiesCost={activitiesCost}
        totalCost={totalCost}
        questionNumber={questionNumber}
        renderNextQuestion={renderNextQuestion}
        renderPrevQuestion={renderPrevQuestion}
        costPer={costPer}
        setCostPer={setCostPer}
        currency={currency}
        setCurrency={setCurrency}
      />

      {questionNumber === 0 && (
        <CitySelector selections={selections} setSelections={setSelections} />
      )}

      {questionNumber === 1 && (
        <Question1
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setAccommodationCost={setAccommodationCost}
        />
      )}
      {questionNumber === 2 && (
        <Question2
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setAccommodationCost={setAccommodationCost}
        />
      )}
      {questionNumber === 3 && (
        <Question3
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setTransportCost={setTransportCost}
        />
      )}
      {questionNumber === 4 && (
        <Question4
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setFoodCost={setFoodCost}
        />
      )}
      {questionNumber === 5 && (
        <Question5
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setFoodCost={setFoodCost}
        />
      )}
      {questionNumber === 6 && (
        <Question6
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setProductCost={setProductCost}
        />
      )}
      {questionNumber === 7 && (
        <Question7
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setClothingCost={setClothingCost}
        />
      )}
      {questionNumber === 8 && (
        <Question8
          selections={selections}
          setSelections={setSelections}
          renderNextQuestion={renderNextQuestion}
          setActivitiesCost={setActivitiesCost}
        />
      )}
      {questionNumber === 9 && (
        <Result
          selections={selections}
          totalCost={totalCost}
          accommodationCost={accommodationCost}
          transportCost={transportCost}
          foodCost={foodCost}
          productCost={productCost}
          clothingCost={clothingCost}
          activitiesCost={activitiesCost}
          costPer={costPer}
          currency={currency}
        />
      )}
      <Footer />
    </div>
  );
};

export default QuizBody;
