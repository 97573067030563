import React from "react";
import cityCostData from "../../Data-sets/StudyQld-cost-data";
import styled from "styled-components";

import { ReactComponent as BreakfastIcon } from "../../assets/icons/breakfast.svg";
import { ReactComponent as LunchIcon } from "../../assets/icons/lunch.svg";
import { ReactComponent as DinnerIcon } from "../../assets/icons/dinner.svg";

import PopupModal from "../Components/PopupModal";

const QuestionCard = styled.div`
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  gap: 8rem;

  .question-section {
    flex: 1;
  }

  .selections-section {
    flex: 1.5;
  }
`;

const Question4 = ({ selections, setSelections, setFoodCost }) => {
  React.useEffect(() => {
    setFoodCost(
      cityCostData[selections.city].foodPriceRange.eatingOut.breakfast[
        selections.food.eatingOut
      ] *
        selections.food.breakfastOut +
        cityCostData[selections.city].foodPriceRange.eatingOut.lunch[
          selections.food.eatingOut
        ] *
          selections.food.lunchOut +
        cityCostData[selections.city].foodPriceRange.eatingOut.dinner[
          selections.food.eatingOut
        ] *
          selections.food.dinnerOut +
        (cityCostData[selections.city].foodPriceRange.atHome.breakfast[
          selections.food.atHome
        ] *
          (7 - selections.food.breakfastOut) +
          cityCostData[selections.city].foodPriceRange.atHome.lunch[
            selections.food.atHome
          ] *
            (7 - selections.food.lunchOut) +
          cityCostData[selections.city].foodPriceRange.atHome.dinner[
            selections.food.atHome
          ] *
            (7 - selections.food.dinnerOut))
    );
  }, []);

  function onChangeHandler(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          food: {
            ...prevSelection.food,
            [event.target.name]: event.target.value,
          },
        };
        setFoodCost(
          cityCostData[selections.city].foodPriceRange.eatingOut.breakfast[
            updatedSelections.food.eatingOut
          ] *
            updatedSelections.food.breakfastOut +
            cityCostData[selections.city].foodPriceRange.eatingOut.lunch[
              updatedSelections.food.eatingOut
            ] *
              updatedSelections.food.lunchOut +
            cityCostData[selections.city].foodPriceRange.eatingOut.dinner[
              updatedSelections.food.eatingOut
            ] *
              updatedSelections.food.dinnerOut +
            (cityCostData[selections.city].foodPriceRange.atHome.breakfast[
              updatedSelections.food.atHome
            ] *
              (7 - updatedSelections.food.breakfastOut) +
              cityCostData[selections.city].foodPriceRange.atHome.lunch[
                updatedSelections.food.atHome
              ] *
                (7 - updatedSelections.food.lunchOut) +
              cityCostData[selections.city].foodPriceRange.atHome.dinner[
                updatedSelections.food.atHome
              ] *
                (7 - updatedSelections.food.dinnerOut))
        );
        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text" tabIndex={0}>
          How many times a week will you be eating out or ordering food
          delivery?
        </div>
        <PopupModal
          modalHeading="How many times a week will you be eating out or ordering food
          delivery?"
          modalContent={
            <div className="modal-info">
              <p tabIndex={0}>
                Buying groceries to make meals at home can be a lot cheaper and
                healthier than dining out. There are also many tips and tricks
                you can use to make your weekly grocery bill more affordable.
                For example, apps like Half Price can help you search through
                supermarket catalogues to find sales more easily. You can also
                shop at local markets to find incredible bargains of fresh
                produce and other food items.
              </p>
              <p tabIndex={0}>
                Read more:{" "}
                {/* <a
                  href="https://www.studymelbourne.vic.gov.au/living-here/money-and-budgeting/the-cost-of-living-in-victoria"
                  target="_blank"
                  rel="noreferrer"
                >
                  The-cost-of-living-in-victoria
                </a> */}
              </p>
            </div>
          }
        />
      </div>

      <div className="non-grid-options-section">
        <div className="range-wrapper">
          <BreakfastIcon />
          <div className="options-wrapper">
            <label className="range-label" htmlFor="breakfastOut">
              <p>Breakfast</p>
              <p>{selections.food.breakfastOut} days</p>
            </label>
            <input
              type="range"
              id="breakfastOut"
              name="breakfastOut"
              value={selections.food.breakfastOut || 0}
              min="0"
              max="7"
              step="1"
              onInput={(event) => onChangeHandler(event)()}
              aria-label="Select number of days for breakfast out"
              aria-valuemin="0"
              aria-valuemax="7"
              aria-valuenow={selections.food.breakfastOut || 0}
            ></input>
          </div>
        </div>

        <div className="range-wrapper">
          <LunchIcon />
          <div className="options-wrapper">
            <label className="range-label" htmlFor="lunchOut">
              <p>Lunch</p>
              <p>{selections.food.lunchOut} days</p>
            </label>
            <input
              type="range"
              id="lunchOut"
              name="lunchOut"
              value={selections.food.lunchOut || 0}
              min="0"
              max="7"
              step="1"
              onInput={(event) => onChangeHandler(event)()}
              aria-label="Select number of days for lunch out"
              aria-valuemin="0"
              aria-valuemax="7"
              aria-valuenow={selections.food.lunchOut || 0}
            ></input>
          </div>
        </div>

        <div className="range-wrapper">
          <DinnerIcon />
          <div className="options-wrapper">
            <label className="range-label" htmlFor="dinnerOut">
              <p>Dinner</p>
              <p>{selections.food.dinnerOut} days</p>
            </label>
            <input
              type="range"
              id="dinnerOut"
              name="dinnerOut"
              value={selections.food.dinnerOut || 0}
              min="0"
              max="7"
              step="1"
              onInput={(event) => onChangeHandler(event)()}
              aria-label="Select number of days for dinner out"
              aria-valuemin="0"
              aria-valuemax="7"
              aria-valuenow={selections.food.dinnerOut || 0}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question4;
