import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledStatusBar = styled.div`
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  padding: 0.5rem 2rem;
  margin-bottom: 4rem;

  .cost-quicklook,
  .cost-info,
  .nav-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

const StatusBar = ({
  accommodationCost,
  transportCost,
  foodCost,
  productCost,
  clothingCost,
  activitiesCost,
  totalCost,
  questionNumber,
  renderNextQuestion,
  renderPrevQuestion,
  costPer,
  setCostPer,
  currency,
  setCurrency,
}) => {
  return (
    <StyledStatusBar>
      <div className="cost-info">
        <FontAwesomeIcon
          icon="fa-solid fa-calculator"
          size="2xl"
          style={{ color: "var(--brand-color)" }}
        />
        <select
          name="currency"
          id="currency"
          onChange={(event) => {
            setCurrency({
              name: event.target.value,
              symbol: event.target.selectedOptions[0].dataset.symbol,
              exchangeRate:
                event.target.selectedOptions[0].dataset.exchangerate,
            });
            console.log(currency);
          }}
        >
          <option data-symbol="$" value="AUD" data-exchangerate="1">
            $ AUD
          </option>
          <option data-symbol="$" value="USD" data-exchangerate="0.74">
            $ USD
          </option>
          <option data-symbol="₹" value="INR" data-exchangerate="54.29">
            ₹ INR
          </option>
          <option data-symbol="Rp" value="IDR" data-exchangerate="10625.00">
            Rp IDR
          </option>
          <option data-symbol="Rp" value="MYR" data-exchangerate="3.10">
            RM MYR
          </option>
          <option data-symbol="¥" value="CNY" data-exchangerate="4.75">
            ¥ CNY
          </option>
          <option data-symbol="₫" value="VND" data-exchangerate="17000.00">
            ₫ VND
          </option>
          <option data-symbol="฿" value="THB" data-exchangerate="23.50">
            ฿ THB
          </option>
          <option data-symbol="₩" value="KRW" data-exchangerate="850.00">
            ₩ KRW
          </option>
          <option data-symbol="RS" value="NPR" data-exchangerate="87.50">
            RS NPR
          </option>
          <option data-symbol="RS" value="LKR" data-exchangerate="148.00">
            RS LKR
          </option>
          <option data-symbol="R$" value="BRL" data-exchangerate="3.85">
            R$ BRL
          </option>
        </select>
        <select
          name="per"
          id="per"
          onChange={(event) => {
            setCostPer(event.target.value);
          }}
        >
          <option value="1">Weekly</option>
          <option value="4">Monthly</option>
          <option value="52">Annually</option>
        </select>
      </div>
      <div className="cost-quicklook">
        <FontAwesomeIcon
          icon="fa-solid fa-chart-pie"
          size="2xl"
          style={{ color: "var(--brand-color)" }}
        />

        {(questionNumber === 1 || questionNumber === 2) && (
          <p>
            Accommodation : {currency.symbol}
            {accommodationCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 3 && (
          <p>
            Transport: {currency.symbol}
            {transportCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 4 && (
          <p>
            Eating out: {currency.symbol}
            {foodCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 5 && (
          <p>
            Eating out: {currency.symbol}
            {foodCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 6 && (
          <p>
            Services: {currency.symbol}
            {productCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 7 && (
          <p>
            Clothing: {currency.symbol}
            {clothingCost * costPer * currency.exchangeRate}
          </p>
        )}
        {questionNumber === 8 && (
          <p>
            Activities: {currency.symbol}
            {activitiesCost * costPer * currency.exchangeRate}
          </p>
        )}
        <p>
          Total : {currency.symbol}
          {totalCost * costPer * currency.exchangeRate}
        </p>
      </div>

      <div className="nav-buttons">
        {questionNumber !== 0 && (
          <button className="nav-btn" onClick={renderPrevQuestion}>
            Prev
          </button>
        )}
        {questionNumber !== 0 && questionNumber !== 9 && (
          <span>{questionNumber}/8</span>
        )}
        {questionNumber !== 9 && (
          <button className="nav-btn" onClick={renderNextQuestion}>
            Next
          </button>
        )}
      </div>
    </StyledStatusBar>
  );
};

export default StatusBar;
