import React from "react";
import cityCostData from "../../Data-sets/StudyQld-cost-data";
import PopupModal from "../Components/PopupModal";
import { ReactComponent as HomeStayIcon } from "../../assets/icons/homestay.svg";
import { ReactComponent as SharedHouseIcon } from "../../assets/icons/sharehouse.svg";
import { ReactComponent as OneBedroomUnitIcon } from "../../assets/icons/onebedroomunit.svg";
import { ReactComponent as ManagedStudentAccommodationIcon } from "../../assets/icons/managedapt.svg";

const Question2 = ({ selections, setSelections, setAccommodationCost }) => {
  React.useEffect(() => {
    setAccommodationCost(
      cityCostData[selections.city][selections.accommodation.location][
        selections.accommodation.accommodationType
      ] || 0
    );
  }, []);

  function handleClick(choice) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          accommodation: {
            ...prevSelection.accommodation,
            accommodationType: choice,
          },
        };
        setAccommodationCost(
          cityCostData[selections.city][
            updatedSelections.accommodation.location
          ][updatedSelections.accommodation.accommodationType] || 0
        );

        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          What type of accommodation are you interested in?
        </div>
        <PopupModal
          modalHeading="Types of accommodation"
          modalContent={
            <div className="modal-info">
              <p tabIndex="0">
                Finding somewhere to live is a crucial component of a successful
                study experience in Australia. Whatever you choose, it's
                important to fully understand all of your options and stay aware
                of your rights and responsibilities.
              </p>
              <h3 tabIndex="0">Homestay</h3>
              <p tabIndex="0">
                If you choose a homestay, you’ll be sure to receive a warm
                welcome to Australia! Homestay is an excellent choice for many
                international students as it offers a valuable cultural exchange
                with your local host family. The upfront price of homestays can
                seem higher, as all utilities costs and meals are covered. The
                family you live with will provide you with a bedroom, pay all
                the utility bills, and make some or all of your daily meals.
                <br />
                <small tabIndex="0">
                  *The figure is based on the fee structure of the Australian
                  Homestay Network as of July 2024.
                </small>
              </p>
              <h3 tabIndex="0">Sharehouse</h3>
              <p tabIndex="0">
                Living in a sharehouse will introduce you to many new people and
                potential friends. The other advantage of living in a sharehouse
                is that you can split the cost of utilities with your
                housemates. While you will still be responsible for providing
                your own food, some housemates may be willing to cook meals
                together.
                <br />
                <small tabIndex="0">
                  *The figure is based on a statistical sample of local market
                  rental data as of April factoring in utility costs.
                </small>
              </p>
              <h3 tabIndex="0">Managed Student Accommodation</h3>
              <p tabIndex="0">
                If you’d like to live with other students and have the benefit
                of an all-inclusive set-up, you should consider managed student
                accommodation. Student apartments are built around most major
                university and college localities and are ready for you to move
                right in. All cleaning and utilities are taken care of, but you
                will have to provide your own food.
                <br />
                <small tabIndex="0">
                  *The figure is based upon the rates of UniLodge as of April
                  2024.
                </small>
              </p>
              <h3 tabIndex="0">1 bedroom apartment/unit</h3>
              <p tabIndex="0">
                If you’re an independent person, then private rental
                accommodation is the perfect option for you. It is also an
                excellent way to fully immerse yourself in Australian culture;
                living with locals will broaden your horizons in your new city.
                But, this greater level of freedom comes with a greater degree
                of responsibility and can lead to higher expenses. You will be
                entirely responsible for paying all bills, making all of your
                own meals and doing all required cleaning.
              </p>
              <p tabIndex="0">
                <small tabIndex="0">
                  *The figure is based on a statistical sample of local market
                  rental data as of July 2024, factoring in utility costs.
                </small>
              </p>
            </div>
          }
        />
      </div>
      <div
        className="options-section"
        role="radiogroup"
        aria-labelledby="accommodation-options"
      >
        <div id="accommodation-options" className="sr-only">
          Accommodation Options
        </div>
        <button
          className={`option-btn ${
            selections.accommodation?.accommodationType === "homestay"
              ? "selected"
              : ""
          }`}
          onClick={handleClick("homestay")}
          role="radio"
          aria-checked={
            selections.accommodation?.accommodationType === "homestay"
          }
          aria-label="Homestay"
        >
          <div className="svg-container">
            <HomeStayIcon className="svg-content" aria-hidden="true" />
          </div>
          Homestay
        </button>
        <button
          className={`option-btn ${
            selections.accommodation?.accommodationType === "shared"
              ? "selected"
              : ""
          }`}
          onClick={handleClick("shared")}
          role="radio"
          aria-checked={
            selections.accommodation?.accommodationType === "shared"
          }
          aria-label="Shared house"
        >
          <div className="svg-container">
            <SharedHouseIcon className="svg-content" aria-hidden="true" />
          </div>
          Shared house
        </button>
        <button
          className={`option-btn ${
            selections.accommodation?.accommodationType === "unit"
              ? "selected"
              : ""
          }`}
          onClick={handleClick("unit")}
          role="radio"
          aria-checked={selections.accommodation?.accommodationType === "unit"}
          aria-label="One bedroom unit"
        >
          <div className="svg-container">
            <OneBedroomUnitIcon className="svg-content" aria-hidden="true" />
          </div>
          One bedroom unit
        </button>
        <button
          className={`option-btn ${
            selections.accommodation?.accommodationType === "managed"
              ? "selected"
              : ""
          }`}
          onClick={handleClick("managed")}
          role="radio"
          aria-checked={
            selections.accommodation?.accommodationType === "managed"
          }
          aria-label="Managed student accommodation"
        >
          <div className="svg-container">
            <ManagedStudentAccommodationIcon
              className="svg-content"
              aria-hidden="true"
            />
          </div>
          Managed student accommodation
        </button>
      </div>
    </div>
  );
};

export default Question2;
