import React from "react";
import cityCostData from "../../Data-sets/StudyQld-cost-data";
import styled from "styled-components";

import { ReactComponent as CityIcon } from "../../assets/icons/city.svg";
import { ReactComponent as SuburbIcon } from "../../assets/icons/suburbs.svg";

import PopupModal from "../Components/PopupModal";

const Question1 = ({ selections, setSelections, setAccommodationCost }) => {
  React.useEffect(() => {
    setAccommodationCost(
      cityCostData[selections.city][selections.accommodation.location][
        selections.accommodation.accommodationType
      ] || 0
    );
  }, []);

  function handleClick(choice) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          accommodation: {
            ...prevSelection.accommodation,
            location: choice,
          },
        };
        setAccommodationCost(
          cityCostData[updatedSelections.city][
            updatedSelections.accommodation.location
          ][updatedSelections.accommodation.accommodationType] || 0
        );

        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text" tabIndex={0}>
          Where would you like to live?
        </div>
        <PopupModal
          modalHeading="Where would you like to live?"
          modalContent={
            <div className="modal-info">
              <p tabIndex={0}>
                Whether you live in the suburbs or the city, there are pros and
                cons to both options. There are a few things to consider when
                making your decision, including travel times to your
                institution, cost of accommodation, proximity to your friends
                and your local community.
              </p>
              <h3 tabIndex={0}>Suburbs</h3>
              <p tabIndex={0}>
                Generally, living in the suburbs is more affordable and houses
                tend to be more spacious. But living further out may mean you
                miss out on lifestyle benefits such as greater entertainment
                options and a higher density of students. *The figure is based
                on a statistical sample of market rental data from a selection
                of suburbs as of July 2024.
              </p>
              <h3 tabIndex={0}>City</h3>
              <p tabIndex={0}>
                Living close to the city will reduce your travel time, increase
                the frequency of transport links and potentially lower your
                travel costs. However, accommodation expenses (such as rent) are
                typically more expensive in the city.
                <br />
                <small tabIndex={0}>
                  *The figure is based on a statistical sample of CBD market
                  rental data as of July 2024.
                </small>
              </p>
            </div>
          }
        />
      </div>
      <div
        className="options-section"
        role="radiogroup"
        aria-labelledby="question-text"
      >
        <button
          className={`option-btn ${
            selections.accommodation?.location === "kindCity" ? "selected" : ""
          }`}
          onClick={handleClick("kindCity")}
          aria-pressed={selections.accommodation?.location === "kindCity"}
          aria-label="City"
        >
          <div className="svg-container">
            <CityIcon className="svg-content" />
          </div>
          City
        </button>
        <button
          className={`option-btn ${
            selections.accommodation?.location === "kindSuburb"
              ? "selected"
              : ""
          }`}
          onClick={handleClick("kindSuburb")}
          aria-pressed={selections.accommodation?.location === "kindSuburb"}
          aria-label="Suburbs"
        >
          <div className="svg-container">
            <SuburbIcon className="svg-content" />
          </div>
          Suburbs
        </button>
      </div>
    </div>
  );
};

export default Question1;
