import React from "react";
import cityCostData from "../../Data-sets/StudyQld-cost-data";

import { ReactComponent as GymIcon } from "../../assets/icons/gym.svg";
import { ReactComponent as HaircutIcon } from "../../assets/icons/shaver.svg";
import { ReactComponent as CosmeticsIcon } from "../../assets/icons/cosmetics.svg";
import { ReactComponent as MobileIcon } from "../../assets/icons/mobile.svg";

import PopupModal from "../Components/PopupModal";

const Question6 = ({ selections, setSelections, setProductCost }) => {
  React.useEffect(() => {
    setProductCost(
      cityCostData[selections.city].productsRate.gyms[
        selections.products.gyms
      ] +
        cityCostData[selections.city].productsRate.haircuts[
          selections.products.haircuts
        ] +
        cityCostData[selections.city].productsRate.cosmetics[
          selections.products.cosmetics
        ] +
        cityCostData[selections.city].productsRate.mobilePlan[
          selections.products.mobilePlan
        ]
    );
  }, []);

  function onChangeHandler(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          products: {
            ...prevSelection.products,
            [event.target.name]: event.target.value,
          },
        };
        setProductCost(
          cityCostData[selections.city].productsRate.gyms[
            updatedSelections.products.gyms
          ] +
            cityCostData[selections.city].productsRate.haircuts[
              updatedSelections.products.haircuts
            ] +
            cityCostData[selections.city].productsRate.cosmetics[
              updatedSelections.products.cosmetics
            ] +
            cityCostData[selections.city].productsRate.mobilePlan[
              updatedSelections.products.mobilePlan
            ]
        );
        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          What level of services or products do you require?
        </div>
        <PopupModal
          modalHeading="What level of services of products do you require?"
          modalContent={
            <div class="modal-info">
              <p tabIndex={0}>
                These products and services will vary significantly from person
                to person, so only choose what's relevant to you. You may also
                want to consider which of these aren't essential. By adjusting
                your habits and tastes, you may be able to cut down on some of
                these costs.
              </p>
              <p tabIndex={0}>
                *Figures are based upon a range of relevant products and service
                providers as of July 2024.
              </p>
            </div>
          }
        />
      </div>
      <div className="non-grid-options-section">
        <div className="radio-wrapper">
          <GymIcon />
          <div className="options-wrapper">
            <p>Gyms</p>
            <div className="option">
              <input
                type="radio"
                name="gyms"
                value="none"
                checked={selections.products.gyms === "none"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "gyms", value: "none" },
                    })();
                  }
                }}
              >
                None
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="gyms"
                value="cheap"
                checked={selections.products.gyms === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "gyms", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="gyms"
                value="moderate"
                checked={selections.products.gyms === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "gyms", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="gyms"
                value="expensive"
                checked={selections.products.gyms === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "gyms", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <HaircutIcon />
          <div className="options-wrapper">
            <p>Haircuts</p>
            <div className="option">
              <input
                type="radio"
                name="haircuts"
                value="none"
                checked={selections.products.haircuts === "none"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "haircuts", value: "none" },
                    })();
                  }
                }}
              >
                None
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="haircuts"
                value="cheap"
                checked={selections.products.haircuts === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "haircuts", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="haircuts"
                value="moderate"
                checked={selections.products.haircuts === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "haircuts", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="haircuts"
                value="expensive"
                checked={selections.products.haircuts === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "haircuts", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <CosmeticsIcon />
          <div className="options-wrapper">
            <p>Cosmetics</p>
            <div className="option">
              <input
                type="radio"
                name="cosmetics"
                value="none"
                checked={selections.products.cosmetics === "none"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cosmetics", value: "none" },
                    })();
                  }
                }}
              >
                None
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cosmetics"
                value="cheap"
                checked={selections.products.cosmetics === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cosmetics", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cosmetics"
                value="moderate"
                checked={selections.products.cosmetics === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cosmetics", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cosmetics"
                value="expensive"
                checked={selections.products.cosmetics === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cosmetics", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <MobileIcon />
          <div className="options-wrapper">
            <p>Mobile Plan</p>
            <div className="option">
              <input
                type="radio"
                name="mobilePlan"
                value="none"
                checked={selections.products.mobilePlan === "none"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "mobilePlan", value: "none" },
                    })();
                  }
                }}
              >
                None
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="mobilePlan"
                value="cheap"
                checked={selections.products.mobilePlan === "cheap"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "mobilePlan", value: "cheap" },
                    })();
                  }
                }}
              >
                Cheap
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="mobilePlan"
                value="moderate"
                checked={selections.products.mobilePlan === "moderate"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "mobilePlan", value: "moderate" },
                    })();
                  }
                }}
              >
                Moderate
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="mobilePlan"
                value="expensive"
                checked={selections.products.mobilePlan === "expensive"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "mobilePlan", value: "expensive" },
                    })();
                  }
                }}
              >
                Expensive
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question6;
