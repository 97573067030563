import React from "react";
import styled from "styled-components";
import cityCostData from "../../Data-sets/StudyQld-cost-data";

import { ReactComponent as ShirtIcon } from "../../assets/icons/shirt.svg";
import { ReactComponent as TShirtIcon } from "../../assets/icons/tshirt.svg";
import { ReactComponent as SuitIcon } from "../../assets/icons/suit.svg";

import PopupModal from "../Components/PopupModal";

const QuestionCard = styled.div`
  display: flex;
  max-width: var(--max-width);
  margin: 0 auto;

  .question-text-section {
    flex: 1;
    display: flex;
  }

  .options-section {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 1rem;
    align-items: center;
  }
`;

const Question7 = ({ selections, setSelections, setClothingCost }) => {
  React.useEffect(() => {
    setClothingCost(
      (selections.clothing.quantity / 4) *
        cityCostData[selections.city].clothing.rate[selections.clothing.cost]
    );
  }, []);

  function handleClick(choice) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          clothing: {
            ...prevSelection.clothing,
            cost: choice,
          },
        };

        setClothingCost(
          (updatedSelections.clothing.quantity / 4) *
            cityCostData[selections.city].clothing.rate[
              updatedSelections.clothing.cost
            ]
        );

        return updatedSelections;
      });
    };
  }

  function changeQuantityOfClothes(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          clothing: {
            ...prevSelection.clothing,
            [event.target.name]: event.target.value,
          },
        };

        setClothingCost(
          (updatedSelections.clothing.quantity / 4) *
            cityCostData[selections.city].clothing.rate[
              updatedSelections.clothing.cost
            ]
        );

        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text" tabIndex={0}>
          What price range do your clothing purchases fit into?
        </div>
        <PopupModal
          modalHeading="What price range do your clothing purchases fit into?"
          modalContent={
            <div class="modal-info">
              <p tabIndex={0}>
                Staying up to date on the latest fashions doesn't have to break
                the bank - all you need is a bit of know-how! To save money on
                clothing purchases, consider visiting op-shops like the
                Salvation Army or Vinnie's. Here you'll find secondhand
                clothing, shoes and accessories that are both affordable and
                sustainable. Shopping online is another great money-saving
                technique as you can often discount codes and use cashback
                browser installations, such as Cashrewards or Shopback.
              </p>
              <p tabIndex={0}>
                *Figures are based upon the prices of a range of common items
                from a selection of department stores as of July 2024.
              </p>
            </div>
          }
        />
      </div>

      <div className="non-grid-options-section">
        <div className="clothing-options-grid">
          <button
            className={`option-btn ${
              selections.clothing?.cost === "cheap" ? "selected" : ""
            }`}
            onClick={handleClick("cheap")}
          >
            <div className="svg-container">
              <TShirtIcon className="svg-content" />
            </div>
            Cheap
          </button>
          <button
            className={`option-btn ${
              selections.clothing?.cost === "moderate" ? "selected" : ""
            }`}
            onClick={handleClick("moderate")}
          >
            <div className="svg-container">
              <ShirtIcon className="svg-content" />
            </div>
            Moderate
          </button>
          <button
            className={`option-btn ${
              selections.clothing?.cost === "expensive" ? "selected" : ""
            }`}
            onClick={handleClick("expensive")}
          >
            <div className="svg-container">
              <SuitIcon className="svg-content" />
            </div>
            Expensive
          </button>
        </div>

        <div className="range-label">
          <p>How many items per month?</p>
          <p>{selections.clothing.quantity}</p>
        </div>
        <input
          id="clothing-range-slider"
          type="range"
          name="quantity"
          value={selections.clothing?.quantity || 0}
          min="0"
          max="10"
          step="1"
          onInput={(event) => changeQuantityOfClothes(event)()}
          aria-valuemin="0"
          aria-valuemax="10"
          aria-valuenow={selections.clothing?.quantity || 0}
        ></input>
      </div>
    </div>
  );
};

export default Question7;
