const cityCostData = {
  Brisbane: {
    printedName: "Brisbane",
    kindCity: {
      homestay: 340,
      shared: 360,
      unit: 687,
      managed: 345,
    },

    kindSuburb: {
      homestay: 340,
      shared: 283,
      unit: 632,
      managed: 345,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 15,
        moderate: 18,
        expensive: 24,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 18,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 30,
        fortnightly: 15,
        monthly: 8,
      },
      socialSport: {
        never: 0,
        weekly: 13,
        fortnightly: 7,
        monthly: 4,
      },
      nightOut: {
        never: 0,
        weekly: 80,
        fortnightly: 40,
        monthly: 20,
      },
    },
  },

  //Townsville Data here
  Townsville: {
    printedName: "Townsville",
    kindCity: {
      homestay: 360,
      shared: 245,
      unit: 383,
      managed: 269,
    },
    kindSuburb: {
      homestay: 360,
      shared: 245,
      unit: 383,
      managed: 269,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 5,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },
    productsRate: {
      gyms: {
        none: 0,
        cheap: 16,
        moderate: 28,
        expensive: 29,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 18,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 30,
        fortnightly: 15,
        monthly: 8,
      },
      socialSport: {
        never: 0,
        weekly: 8,
        fortnightly: 4,
        monthly: 2,
      },
      nightOut: {
        never: 0,
        weekly: 71,
        fortnightly: 36,
        monthly: 18,
      },
    },
  },

  //Rockhampton data here
  Rockhampton: {
    printedName: "Rockhampton",
    kindCity: {
      homestay: 360,
      shared: 216,
      unit: 456,
      managed: 370,
    },

    kindSuburb: {
      homestay: 360,
      shared: 216,
      unit: 456,
      managed: 370,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 18,
        moderate: 25,
        expensive: 25,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 18,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 40,
        fortnightly: 20,
        monthly: 10,
      },
      socialSport: {
        never: 0,
        weekly: 5,
        fortnightly: 3,
        monthly: 2,
      },
      nightOut: {
        never: 0,
        weekly: 64,
        fortnightly: 32,
        monthly: 16,
      },
    },
  },

  //Sunshine Coast data here
  SunshineCoast: {
    printedName: "Sunshine Coast",
    kindCity: {
      homestay: 330,
      shared: 321,
      unit: 612,
      managed: 250,
    },

    kindSuburb: {
      homestay: 330,
      shared: 371,
      unit: 612,
      managed: 250,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 16,
        moderate: 17,
        expensive: 23,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 17,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 17,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 50,
        fortnightly: 25,
        monthly: 13,
      },
      socialSport: {
        never: 0,
        weekly: 12,
        fortnightly: 6,
        monthly: 3,
      },
      nightOut: {
        never: 0,
        weekly: 69,
        fortnightly: 35,
        monthly: 18,
      },
    },
  },

  //Gold Coast data here
  GoldCoast: {
    printedName: "Gold Coast",
    kindCity: {
      homestay: 370,
      shared: 325,
      unit: 615,
      managed: 300,
    },

    kindSuburb: {
      homestay: 370,
      shared: 325,
      unit: 615,
      managed: 300,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 19,
        moderate: 21,
        expensive: 25,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 19,
        fortnightly: 10,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 40,
        fortnightly: 20,
        monthly: 10,
      },
      socialSport: {
        never: 0,
        weekly: 10,
        fortnightly: 5,
        monthly: 3,
      },
      nightOut: {
        never: 0,
        weekly: 69,
        fortnightly: 35,
        monthly: 18,
      },
    },
  },

  Cairns: {
    printedName: "Cairns",
    kindCity: {
      homestay: 360,
      shared: 315,
      unit: 459,
      managed: 206,
    },

    kindSuburb: {
      homestay: 360,
      shared: 293,
      unit: 459,
      managed: 206,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 15,
        moderate: 15,
        expensive: 15,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 18,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 30,
        fortnightly: 15,
        monthly: 8,
      },
      socialSport: {
        never: 0,
        weekly: 25,
        fortnightly: 13,
        monthly: 7,
      },
      nightOut: {
        never: 0,
        weekly: 55,
        fortnightly: 28,
        monthly: 14,
      },
    },
  },

  //Toowoomba data here
  Toowoomba: {
    printedName: "Toowoomba",
    kindCity: {
      homestay: 330,
      shared: 246,
      unit: 422,
      managed: 170,
    },

    kindSuburb: {
      homestay: 330,
      shared: 210,
      unit: 422,
      managed: 170,
    },
    transport: {
      bike: {
        never: 0,
        rarely: 0,
        sometimes: 0,
        often: 0,
      },
      publicTransport: {
        never: 0,
        rarely: 1,
        sometimes: 4,
        often: 7,
      },
      car: {
        never: 0,
        rarely: 121,
        sometimes: 128,
        often: 142,
      },
      taxi: {
        never: 0,
        rarely: 11,
        sometimes: 31,
        often: 50,
      },
    },
    foodPriceRange: {
      atHome: {
        breakfast: {
          cheap: 2,
          moderate: 3,
          expensive: 7,
        },
        lunch: {
          cheap: 3,
          moderate: 4,
          expensive: 19,
        },
        dinner: {
          cheap: 13,
          moderate: 14,
          expensive: 16,
        },
      },
      eatingOut: {
        breakfast: {
          cheap: 11,
          moderate: 15,
          expensive: 20,
        },
        lunch: {
          cheap: 17,
          moderate: 22,
          expensive: 35,
        },
        dinner: {
          cheap: 15,
          moderate: 25,
          expensive: 40,
        },
      },
    },

    productsRate: {
      gyms: {
        none: 0,
        cheap: 11,
        moderate: 12,
        expensive: 17,
      },
      haircuts: {
        none: 0,
        cheap: 6,
        moderate: 9,
        expensive: 15,
      },
      cosmetics: {
        none: 0,
        cheap: 4,
        moderate: 7,
        expensive: 14,
      },
      mobilePlan: {
        none: 0,
        cheap: 16,
        moderate: 18,
        expensive: 24,
      },
    },
    clothing: {
      rate: {
        cheap: 37,
        moderate: 81,
        expensive: 237,
      },
    },
    activityFrequency: {
      cinemas: {
        never: 0,
        weekly: 18,
        fortnightly: 9,
        monthly: 5,
      },
      liveMusic: {
        never: 0,
        weekly: 40,
        fortnightly: 20,
        monthly: 10,
      },
      socialSport: {
        never: 0,
        weekly: 15,
        fortnightly: 8,
        monthly: 4,
      },
      nightOut: {
        never: 0,
        weekly: 58,
        fortnightly: 29,
        monthly: 15,
      },
    },
  },
};

export default cityCostData;
