import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5rem;
  background: var(--brand-color);
  color: white;
  border: 3px solid var(--brand-color);

  &:hover {
    background: var(--brand-color);
    color: white;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 5px;
  width: 800px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: popup 0.3s ease-out;

  @keyframes popup {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--brand-color);
    color: white;
    padding: 10px 20px;
  }

  .modal-inner-content {
    height: 480px;
    overflow-y: auto;
    padding: 10px 20px;
  }
`;

const CloseButton = styled.button`
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
`;

const PopupModal = ({ modalHeading, modalContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const buttonRef = useRef(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    buttonRef.current.focus();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div>
      <Button onClick={openModal} ref={buttonRef}>
        More info &nbsp;
        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
      </Button>
      {isOpen && (
        <ModalOverlay onClick={handleOverlayClick} onKeyDown={handleKeyDown}>
          <ModalContent
            role="dialog"
            aria-labelledby="modal-heading"
            aria-describedby="modal-content"
            tabIndex="-1"
            ref={modalRef}
          >
            <div className="modal-header">
              <h3 id="modal-heading">{modalHeading}</h3>
              <CloseButton onClick={closeModal} aria-label="Close modal">
                &times;
              </CloseButton>
            </div>
            <div className="modal-inner-content" id="modal-content">
              {modalContent}
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default PopupModal;
