import React from "react";
import styled from "styled-components";

import cityCostData from "../../Data-sets/StudyQld-cost-data";

import { ReactComponent as BikeIcon } from "../../assets/icons/bike.svg";
import { ReactComponent as PublicTransportIcon } from "../../assets/icons/pubtransport.svg";
import { ReactComponent as CarIcon } from "../../assets/icons/car.svg";
import { ReactComponent as TaxiIcon } from "../../assets/icons/taxi.svg";
import PopupModal from "../Components/PopupModal";

const Question3 = ({ selections, setSelections, setTransportCost }) => {
  React.useEffect(() => {
    setTransportCost(
      (cityCostData[selections.city].transport.bike[
        selections.transport.bike
      ] || 0) +
        (cityCostData[selections.city].transport.publicTransport[
          selections.transport.publicTransport
        ] || 0) +
        (cityCostData[selections.city].transport.car[
          selections.transport.car
        ] || 0) +
        (cityCostData[selections.city].transport.taxi[
          selections.transport.taxi
        ] || 0)
    );
  }, []);

  function radioClickHandler(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          transport: {
            ...prevSelection.transport,
            [event.target.name]: event.target.value,
          },
        };
        setTransportCost(
          (cityCostData[selections.city].transport.bike[
            updatedSelections.transport.bike
          ] || 0) +
            (cityCostData[selections.city].transport.publicTransport[
              updatedSelections.transport.publicTransport
            ] || 0) +
            (cityCostData[selections.city].transport.car[
              updatedSelections.transport.car
            ] || 0) +
            (cityCostData[selections.city].transport.taxi[
              updatedSelections.transport.taxi
            ] || 0)
        );
        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          How often will you be using these forms of transport?
        </div>
        <PopupModal
          modalHeading="How often will you be using these forms of transport?"
          modalContent={
            <div className="modal-info">
              <p tabIndex={0}>
                Whether you are walking, riding a bike, catching public
                transport or driving a car, you need to know the best way to get
                from A to B. Think about where you plan to live and how that
                will impact travel times and available transport options.
              </p>
              <h3 tabIndex={0}>Walking or cycling</h3>
              <p tabIndex={0}>
                If you’d like to avoid public transport or don’t want to own a
                car, you have a couple of great options for getting around.
              </p>
              <p tabIndex={0}>
                The cheapest and easiest way to get anywhere in your new city is
                by using your own two feet. You don’t need to worry about bus
                timetables, or whether you’ve caught the right train - just
                budget your time correctly and get out in the fresh air to
                explore your city.
              </p>
              <p tabIndex={0}>
                If walking isn’t your style, buying a bicycle is a good
                investment and an excellent way to save money as a student. Not
                only will it get you around for free, but it will keep you fit
                and healthy too! If you’re considering buying a bike, make sure
                you purchase the right kind as there are different types of
                bikes (e.g. road bikes, mountain bikes, etc.)
              </p>
              <h3 tabIndex={0}>Public Transport</h3>
              <p tabIndex={0}>
                Getting around in Australia is relatively easy in most cities.
                Public transport options are abundant, as Australian cities use
                trams, trains, buses and ferries. The exact public transport
                options vary between cities, so make sure you consider the type
                of public transport that’s available and best suits your needs.
              </p>
              <h3 tabIndex={0}>Car</h3>
              <p tabIndex={0}>
                Depending on where you are in Australia - such as in regional,
                remote areas - you might never need a car. But if you want to
                get out and about and explore your new home, a car offers you
                the freedom to do just that. If you’re interested in buying a
                car, don’t forget to include things like petrol, registration
                and insurance in your budget.
              </p>
              <h3 tabIndex={0}>Taxi/Uber</h3>
              <p tabIndex={0}>
                In most major cities, you can hail a taxi off the street. You
                can also book a taxi in advance online or by calling ahead.
                Another option is to use Uber, which is a simple ridesharing app
                you can download and use on your phone. Uber is an on-demand
                service and usually offers cheaper fares than taxis.
              </p>
            </div>
          }
        />
      </div>

      <div className="non-grid-options-section">
        <div className="radio-wrapper">
          <BikeIcon />
          <div className="options-wrapper">
            <p>Bicycle/walking</p>
            <div className="option">
              <input
                type="radio"
                name="bike"
                value="never"
                checked={selections.transport.bike === "never"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "bike", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="bike"
                value="rarely"
                checked={selections.transport.bike === "rarely"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "bike", value: "rarely" },
                    })();
                  }
                }}
              >
                Rarely
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="bike"
                value="sometimes"
                checked={selections.transport.bike === "sometimes"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "bike", value: "sometimes" },
                    })();
                  }
                }}
              >
                Sometimes
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="bike"
                value="often"
                checked={selections.transport.bike === "often"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "bike", value: "often" },
                    })();
                  }
                }}
              >
                Often
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <PublicTransportIcon />
          <div className="options-wrapper">
            <p id="publicTransport">Public Transport</p>
            <div className="option">
              <input
                type="radio"
                name="publicTransport"
                value="never"
                checked={selections.transport.publicTransport === "never"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "publicTransport", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="publicTransport"
                value="rarely"
                checked={selections.transport.publicTransport === "rarely"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "publicTransport", value: "rarely" },
                    })();
                  }
                }}
              >
                Rarely
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="publicTransport"
                value="sometimes"
                checked={selections.transport.publicTransport === "sometimes"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "publicTransport", value: "sometimes" },
                    })();
                  }
                }}
              >
                Sometimes
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="publicTransport"
                value="often"
                checked={selections.transport.publicTransport === "often"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "publicTransport", value: "often" },
                    })();
                  }
                }}
              >
                Often
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <CarIcon />
          <div className="options-wrapper">
            <p>Car</p>
            <div className="option">
              <input
                type="radio"
                name="car"
                value="never"
                checked={selections.transport.car === "never"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "car", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="car"
                value="rarely"
                checked={selections.transport.car === "rarely"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "car", value: "rarely" },
                    })();
                  }
                }}
              >
                Rarely
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="car"
                value="sometimes"
                checked={selections.transport.car === "sometimes"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "car", value: "sometimes" },
                    })();
                  }
                }}
              >
                Sometimes
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="car"
                value="often"
                checked={selections.transport.car === "often"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "car", value: "often" },
                    })();
                  }
                }}
              >
                Often
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <TaxiIcon />
          <div className="options-wrapper">
            <p>Rideshare/Taxi</p>
            <div className="option">
              <input
                type="radio"
                name="taxi"
                value="never"
                checked={selections.transport.taxi === "never"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "taxi", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="taxi"
                value="rarely"
                checked={selections.transport.taxi === "rarely"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "taxi", value: "rarely" },
                    })();
                  }
                }}
              >
                Rarely
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="taxi"
                value="sometimes"
                checked={selections.transport.taxi === "sometimes"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "taxi", value: "sometimes" },
                    })();
                  }
                }}
              >
                Sometimes
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="taxi"
                value="often"
                checked={selections.transport.taxi === "often"}
                onChange={(event) => radioClickHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    radioClickHandler({
                      target: { name: "taxi", value: "often" },
                    })();
                  }
                }}
              >
                Often
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question3;
