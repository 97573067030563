import React, { act } from "react";
import cityCostData from "../../Data-sets/StudyQld-cost-data";

import { ReactComponent as CinemasIcon } from "../../assets/icons/cinemas.svg";
import { ReactComponent as LiveMusicIcon } from "../../assets/icons/livemusic.svg";
import { ReactComponent as SocialSportIcon } from "../../assets/icons/socialsport.svg";
import { ReactComponent as NightOutIcon } from "../../assets/icons/nightout.svg";

import PopupModal from "../Components/PopupModal";

const Question8 = ({ selections, setSelections, setActivitiesCost }) => {
  React.useEffect(() => {
    setActivitiesCost(
      cityCostData[selections.city].activityFrequency.cinemas[
        selections.activities.cinemas
      ] +
        cityCostData[selections.city].activityFrequency.liveMusic[
          selections.activities.liveMusic
        ] +
        cityCostData[selections.city].activityFrequency.socialSport[
          selections.activities.socialSport
        ] +
        cityCostData[selections.city].activityFrequency.nightOut[
          selections.activities.nightOut
        ]
    );
  }, []);

  function onChangeHandler(event) {
    return () => {
      setSelections((prevSelection) => {
        const updatedSelections = {
          ...prevSelection,
          activities: {
            ...prevSelection.activities,
            [event.target.name]: event.target.value,
          },
        };

        setActivitiesCost(
          cityCostData[selections.city].activityFrequency.cinemas[
            updatedSelections.activities.cinemas
          ] +
            cityCostData[selections.city].activityFrequency.liveMusic[
              updatedSelections.activities.liveMusic
            ] +
            cityCostData[selections.city].activityFrequency.socialSport[
              updatedSelections.activities.socialSport
            ] +
            cityCostData[selections.city].activityFrequency.nightOut[
              updatedSelections.activities.nightOut
            ]
        );

        return updatedSelections;
      });
    };
  }

  return (
    <div className="question-wrapper">
      <div className="question-text-section">
        <div className="question-text">
          How often will you do these activities?
        </div>
        <PopupModal
          modalHeading="How often will you do these activities?"
          modalContent={
            <div class="modal-info">
              <p tabIndex={0}>
                Although you're coming to Australia to study, it's important to
                factor fun and relaxation into your budget. Making friends,
                improving your mental wellbeing and exploring your city are all
                crucial when it comes to maintaining a healthy body and mind.
                So, while these activities can be costly, they're worth
                including in your budget.
              </p>
              <p tabIndex={0}>
                *Figures are based upon the prices of common service providers
                in each field as of July 2024.
              </p>
            </div>
          }
        />
      </div>
      <div className="non-grid-options-section">
        <div className="radio-wrapper">
          <CinemasIcon />
          <div className="options-wrapper">
            <p>Cinemas</p>
            <div className="option">
              <input
                type="radio"
                name="cinemas"
                value="never"
                checked={selections.activities.cinemas === "never"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cinemas", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cinemas"
                value="weekly"
                checked={selections.activities.cinemas === "weekly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cinemas", value: "weekly" },
                    })();
                  }
                }}
              >
                Weekly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cinemas"
                value="fortnightly"
                checked={selections.activities.cinemas === "fortnightly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cinemas", value: "fortnightly" },
                    })();
                  }
                }}
              >
                Fortnightly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="cinemas"
                value="monthly"
                checked={selections.activities.cinemas === "monthly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "cinemas", value: "monthly" },
                    })();
                  }
                }}
              >
                Monthly
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <LiveMusicIcon />
          <div className="options-wrapper">
            <p>Live music / sport</p>
            <div className="option">
              <input
                type="radio"
                name="liveMusic"
                value="never"
                checked={selections.activities.liveMusic === "never"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "liveMusic", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="liveMusic"
                value="weekly"
                checked={selections.activities.liveMusic === "weekly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "liveMusic", value: "weekly" },
                    })();
                  }
                }}
              >
                Weekly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="liveMusic"
                value="fortnightly"
                checked={selections.activities.liveMusic === "fortnightly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "liveMusic", value: "fortnightly" },
                    })();
                  }
                }}
              >
                Fortnightly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="liveMusic"
                value="monthly"
                checked={selections.activities.liveMusic === "monthly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "liveMusic", value: "monthly" },
                    })();
                  }
                }}
              >
                Monthly
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <SocialSportIcon />
          <div className="options-wrapper">
            <p>Social sport / group classes</p>
            <div className="option">
              <input
                type="radio"
                name="socialSport"
                value="never"
                checked={selections.activities.socialSport === "never"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "socialSport", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="socialSport"
                value="weekly"
                checked={selections.activities.socialSport === "weekly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "socialSport", value: "weekly" },
                    })();
                  }
                }}
              >
                Weekly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="socialSport"
                value="fortnightly"
                checked={selections.activities.socialSport === "fortnightly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "socialSport", value: "fortnightly" },
                    })();
                  }
                }}
              >
                Fortnightly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="socialSport"
                value="monthly"
                checked={selections.activities.socialSport === "monthly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "socialSport", value: "monthly" },
                    })();
                  }
                }}
              >
                Monthly
              </label>
            </div>
          </div>
        </div>

        <div className="radio-wrapper">
          <NightOutIcon />
          <div className="options-wrapper">
            <p>Night out</p>
            <div className="option">
              <input
                type="radio"
                name="nightOut"
                value="never"
                checked={selections.activities.nightOut === "never"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "nightOut", value: "never" },
                    })();
                  }
                }}
              >
                Never
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="nightOut"
                value="weekly"
                checked={selections.activities.nightOut === "weekly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "nightOut", value: "weekly" },
                    })();
                  }
                }}
              >
                Weekly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="nightOut"
                value="fortnightly"
                checked={selections.activities.nightOut === "fortnightly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "nightOut", value: "fortnightly" },
                    })();
                  }
                }}
              >
                Fortnightly
              </label>
            </div>
            <div className="option">
              <input
                type="radio"
                name="nightOut"
                value="monthly"
                checked={selections.activities.nightOut === "monthly"}
                onChange={(event) => onChangeHandler(event)()}
              />
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onChangeHandler({
                      target: { name: "nightOut", value: "monthly" },
                    })();
                  }
                }}
              >
                Monthly
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question8;
