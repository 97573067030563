import React from "react";
import styled from "styled-components";
import Sidebar from "../Quiz/Components/Sidebar";

import logo from "../assets/Logos/StudyQLDLogo.png";

//import logo from "../assets/Logos/Study QLD_TIQ_REVERSE.png";

console.log(logo);

const HeaderWrapper = styled.div`
  .top-line {
    background-color: var(--brand-color);
    height: 0.5rem;
    width: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: left;
    margin: 0 auto;
    max-width: var(--max-width);
    padding: 2rem 2rem;
    .logo {
      img {
        height: 60px;
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      color: var(--brand-color);

      a {
        text-decoration: none;
        color: var(--brand-color);
        font-weight: 600;
        font-size: 1.2rem;
      }
    }

    .header-menu {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .header {
      .logo {
        img {
          height: auto;
          width: 60%;
        }
      }

      .navigation {
        display: none;
      }

      .header-menu {
        display: block;
      }
    }
  }
`;

const Banner = styled.div`
  background-color: var(--brand-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <>
      <HeaderWrapper>
        <div className="top-line"></div>
        <header className="header">
          <div
            className="logo"
            onClick={() =>
              window.open("http://studyqueensland.qld.gov.au/", "_blank")
            }
            style={{
              cursor: "pointer",
            }}
          >
            <img src={logo} alt="Study Queensland Logo" />
          </div>
          {/* <nav
            className="navigation"
            role="navigation"
            aria-label="Main Navigation"
          >
            <a href="#study" className="nav-item">
              Study
            </a>
            <a href="#live" className="nav-item">
              Live
            </a>
            <a href="#work" className="nav-item">
              Work
            </a>
            <a href="#why-queensland" className="nav-item">
              Why Queensland
            </a>
            <a href="#industry" className="nav-item">
              Industry
            </a>
          </nav> */}
          <Sidebar />
        </header>
      </HeaderWrapper>

      <Banner
        tabIndex={0}
        role="banner"
        aria-label="Cost of living calculator banner"
      >
        Cost of living calculator
      </Banner>
    </>
  );
};

export default Header;
