import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResultPage = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  padding-inline: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .city-info-section {
    flex: 1;
    .city-title {
      font-size: 3rem;
      line-height: 3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      color: var(--brand-color);
    }
    .total-cost-result {
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 2rem;
      color: var(--brand-color);
    }
    .cost-excerpt {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .breakdown-section {
    flex: 1;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 2em;

    .breakdown-title {
      color: var(--brand-color);
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .bar-list-item {
      .cost-bar-title {
        color: black;
        font-weight: 600;
        margin-bottom: 0.35rem;
        margin-top: 0.5rem;
      }
      .cost-bar-outer {
        background-color: var(--secondary-color);
        height: 2rem;
        border-radius: 1rem;
        width: 100%;
        overflow: hidden;

        .cost-bar-inner {
          background-color: var(--brand-color);
          height: 2rem;
          border-radius: inherit;
          width: 25%;
          display: flex;
          align-items: center;
          color: #fff;

          .cost-bar-text {
            padding-left: 2rem;
            z-index: 2;
          }
        }
      }
    }

    .resBtns {
      display: flex;
      margin-top: 2rem;
      .restart-button {
        background-color: var(--brand-color);
        color: white;
        padding: 10px 22px 8px 22px;
        border: 2px solid var(--brand-color);
        border-radius: 5rem;
        text-decoration: none;
        /* margin-right: 18px; */
        margin: 0.5em 0.25em;
        float: left;
        transition: 0.2s ease all;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
      }
      .learnmore-button {
        background-color: white;
        color: var(--brand-color);
        padding: 10px 22px 8px 22px;
        border: 2px solid var(--brand-color);
        border-radius: 5rem;
        text-decoration: none;
        /* margin-right: 18px; */
        margin: 0.5em 0.25em;
        float: left;
        transition: 0.2s ease all;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
`;

const Result = ({
  selections,
  totalCost,
  accommodationCost,
  transportCost,
  foodCost,
  productCost,
  clothingCost,
  activitiesCost,
  costPer,
  currency,
}) => {
  return (
    <ResultPage>
      <div className="city-info-section">
        <div className="city-title">
          Your cost of living in{" "}
          {selections.city.replace(/([a-z])([A-Z])/g, "$1 $2")}
        </div>
        <div className="total-cost-result">
          <FontAwesomeIcon
            icon="fa-solid fa-sack-dollar"
            style={{ fontSize: "4rem" }}
          />
          &nbsp;&nbsp;{currency.symbol}{" "}
          {totalCost * costPer * currency.exchangeRate}
        </div>
        <div className="cost-excerpt">
          The cost of living tool is powered by Insider Publishing Pty Ltd and
          is for information purposes only. Estimates are indicative and
          designed to be a useful, general guide to costs in Queensland only,
          and do not extensively cover individual circumstances affecting cost
          of living. Study Queensland has used its best endeavours to ensure
          that the information provided is correct and current at the time of
          publication but does not accept any liability to any person for the
          information or advice (or the use of such information or advice) which
          is provided on this cost of living tool or incorporated into it by
          reference. Study Queensland recommends students also refer to types of
          accommodation, money and budgeting and a guide to public transport in
          Queensland, and engage in further independent research before making
          decisions relating to their own or any other person's interest. Latest
          data update: July 2024.
        </div>
      </div>
      <div className="breakdown-section">
        <div className="breakdown-title">SPEND BREAKDOWN</div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Accommodation</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{
                width: `${(accommodationCost / totalCost) * 100}%`,
              }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {accommodationCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Transport</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{ width: `${(transportCost / totalCost) * 100}%` }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {transportCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Food</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{ width: `${(foodCost / totalCost) * 100}%` }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {foodCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Personal</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{ width: `${(productCost / totalCost) * 100}%` }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {productCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Clothing</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{ width: `${(clothingCost / totalCost) * 100}%` }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {clothingCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="bar-list-item">
          <div className="cost-bar-title">Entertainment</div>
          <div className="cost-bar-outer">
            <div
              className="cost-bar-inner"
              style={{ width: `${(activitiesCost / totalCost) * 100}%` }}
            >
              <div className="cost-bar-text">
                {currency.symbol}
                {activitiesCost * costPer * currency.exchangeRate}
              </div>
            </div>
          </div>
        </div>
        <div className="resBtns">
          <button
            className="restart-button"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon icon="fa-solid fa-rotate-left" size="xl" />
            &nbsp;&nbsp;Start again
          </button>
          <button className="learnmore-button">
            <FontAwesomeIcon icon="fa-solid fa-chart-pie" size="xl" />
            &nbsp;&nbsp;Learn more about Money budgeting
          </button>
        </div>
      </div>
    </ResultPage>
  );
};

export default Result;
