import React, { useState } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToggleButton = styled.button`
  display: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseButton = styled.button`
  color: white;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background-color: white;
  z-index: 9999;

  box-sizing: border-box;

  .sidebar-top {
    display: flex;
    justify-content: space-between;
    background-color: var(--brand-color);
    padding-inline: 1rem;
  }

  .sidebar-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-inline: 1rem;
    margin-top: 3rem;

    a {
      color: black;
      text-decoration: none;
      font-size: 1.5rem;
    }
  }
`;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ToggleButton onClick={toggleSidebar} aria-label="Open sidebar">
        <FontAwesomeIcon icon="fa-solid fa-bars" size="2x" />
      </ToggleButton>
      {isOpen && (
        <StyledSidebar role="navigation" aria-label="Sidebar">
          <div className="sidebar-top">
            <p style={{ color: "white", fontWeight: "bold" }}>
              studyqueensland.qld.gov.au
            </p>
            <CloseButton onClick={toggleSidebar} aria-label="Close sidebar">
              <FontAwesomeIcon icon="fa-solid fa-xmark" size="2x" />
            </CloseButton>
          </div>
          <nav className="sidebar-links">
            <a href="">Study</a>
            <a href="">Live</a>
            <a href="">Work</a>
            <a href="">Why Queensland</a>
            <a href="">Industry</a>
          </nav>
        </StyledSidebar>
      )}
    </>
  );
};

export default Sidebar;
